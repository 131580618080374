import axios from "axios";
import {xml2json} from "xml2json-light";
import {DAY_ENDS_AT, DAY_STARTS_AT} from "@/config";

const requireSpots = async (pageUrl) => {
    return axios.post(pageUrl || '/api/spots', null, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(async (response) => {
        let responseData = response.data;
        if(responseData.next_page_url) {
            const nextPage = (await requireSpots(responseData.next_page_url)).data;
            responseData.data = [...responseData.data, ...nextPage.data];
        }
        return response;
    })
}
const requireSpotInfo = (id) => {
    return axios.get('/api/spots/' + id)
        .then((response) => {
            response.data.loaded = true;
            return response;
        })
}
const requireForecast = (spots, id) => {
    return axios.get('/api/forecast', {
        params: {
            lat: spots[id].latitude,
            lng: spots[id].longitude
        }
    }).then(response => xml2json(response.data));
}
const requireStationList = () => {
    return axios.get('https://erddap.marine.ie/erddap/tabledap/IMI-TidePrediction.json?time,stationID,longitude,latitude&time>=2021-06-10T08:00:00Z&time<=2021-06-10T08:04:00Z')
        .then((response) => {
            const rows = response.data.table.rows;
            return rows.reduce((result, row) => {
                const [, stationID, longitude, latitude] = row;
                result[stationID] = {
                    stationID: stationID,
                    longitude: longitude,
                    latitude: latitude
                };
                return result;
            }, {});
        });
}
const requireStationData = (timeFrom, timeTo, stationId) => {
    return axios.get(`https://erddap.marine.ie/erddap/tabledap/IMI-TidePrediction.json?time,Water_Level,Water_Level_ODM&time>=${timeFrom}&time<=${timeTo}&stationID="${stationId}"`)
        .then(response => response.data.table)
}
const partition = (array, isValid) => {
    return array.reduce(([pass, fail], elem, index) => {
        return isValid(elem, index) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    }, [[], []]);
}
const parseDate = (date) => {
    const padNumber = (number) => {
        return number.toString().padStart(2, '0');
    };
    const day = padNumber(date.getDate());
    const month = padNumber(date.getMonth() + 1);
    return `${day}/${month}`;
};
function distance(point, point2) {
    return Math.sqrt(Math.pow(point.longitude - point2.longitude, 2) + Math.pow(point.latitude - point2.latitude, 2))
}
function getPropByString(obj, propString) {
    if (!propString)
        return obj;

    let prop, props = propString.split('.');
    let i = 0;
    for (let iLen = props.length - 1; i < iLen; i++) {
        prop = props[i];

        let candidate = obj[prop];
        if (candidate !== undefined) {
            obj = candidate;
        } else {
            break;
        }
    }
    return obj[props[i]];
}
function getDistanceFromLatLonInKm(point1,point2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(point2.lat()-point1.lat());  // deg2rad below
    var dLon = deg2rad(point2.lng()-point1.lng());
    var a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(point1.lat())) * Math.cos(deg2rad(point2.lat())) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c; // Distance in km
    return d;
}

function deg2rad(deg) {
    return deg * (Math.PI/180)
}

function getImgUrl(date, timestampForecast) {
    const fileExtension = '.png';
    const commonRelativeDir = 'common';
    const nightRelativeDir = 'night';
    const dayRelativeDir = 'day'
    let dir = '/img/forecast-icons';
    const currentHour = new Date(date).getHours();
    const cloudiness = timestampForecast.location.cloudiness.percent * 1.0;
    const precipitation = timestampForecast.location.precipitation.value * 1.0;

    let cloudIndex = Math.floor(cloudiness*5.999999/100);

    if(cloudIndex >= 4) {
        dir += `/${commonRelativeDir}`;
    }
    else if(currentHour >= DAY_STARTS_AT && currentHour <= DAY_ENDS_AT) {
        dir += `/${dayRelativeDir}`;
    } else {
        dir += `/${nightRelativeDir}`;
    }

    let rainIntensity = precipitation >= 2? (precipitation >= 5? 3 : 2) : 1;
    cloudIndex = Math.max(cloudIndex, !!(rainIntensity - 1) * 1.0)

    dir += `/cloudy-${cloudIndex}`;
    dir += `/${rainIntensity}${fileExtension}`;
    return dir;
}

function requireGTM() {
    return axios.get('/api/gtm/').then(e => e.data);
}
async function getGTM() {
    const gtmScriptTag = document.createElement('script');
    const tempDiv = document.createElement('div');
    tempDiv.insertAdjacentHTML('beforeend', await requireGTM());

    const gtmScript = tempDiv.querySelector('script');
    if(!gtmScript) return;

    gtmScriptTag.innerHTML = tempDiv.querySelector('script').innerHTML;
    gtmScriptTag.id = 'gtmScript';
    document.querySelector('head').appendChild(gtmScriptTag);
}
export {
    requireSpots,
    requireSpotInfo,
    requireForecast,
    requireStationList,
    partition,
    parseDate,
    distance,
    getPropByString,
    requireStationData,
    getDistanceFromLatLonInKm,
    getImgUrl,
    getGTM,
}
