<template>
    <overlay>
        <div @click.self="closeView" class="overlay__wrapper">
            <div class="list-view">
                <div class="shell">
                    <div class="list-view__head">
                        <div class="title">
                            <div class="icon">
                                <img src="@/assets/img/list-ul.svg" alt="List">
                            </div>
                            <div class="title__content">
                                Swim Spots - List View
                            </div>
                        </div>
                        <div @click="closeView" class="exit_button">
                            <img src="@/assets/img/x-circle-fill.svg" alt="Exit">
                        </div>
                    </div>
                    <div class="list-view__body">
                        <div class="list-data">
                            <div class="list-wrapper__header">
                                <div class="list__header-entry">Name</div>
                                <div class="list__header-entry">Town</div>
                                <div class="list__header-entry">County</div>
                            </div>
                            <list-row
                                :icon="$options.pinBlack"
                                :spots-to-show="spotsToShow.exact"
                                @select-spot="selectSpot($event)"
                            >
                            </list-row>
                            <list-row
                                :icon="$options.pinDefault"
                                :spots-to-show="spotsToShow.close"
                                @select-spot="selectSpot($event)"
                            >
                            </list-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </overlay>
</template>

<script>
    import Overlay from '@/components/Overlay';
    import ListRow from "@/components/List/ListRow";
    import "@/assets/scss/list-view/index.scss";
    export default {
        props: ['spotsToShow'],
        components: {
            Overlay,
            ListRow
        },
        pinDefault: require('@/assets/img/swim-map-pin.svg'),
        pinBlack: require('@/assets/img/swim-map-pin-black.svg'),
        methods: {
            closeView() {
                this.$emit('closeList')
            },
            selectSpot(id) {
                this.$emit('selectSpot', id);
            }
        }
    }
</script>

