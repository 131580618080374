<template>
    <div>
        <nav-primary></nav-primary>
        <Map
            @spotFocus="this.$emit('spotFocus', $event)"
            v-if="isGoogleLoaded.value"></Map>
    </div>
</template>

<script>
import NavPrimary from '@/components/Nav/NavPrimary.vue'
import Map from '@/components/Map.vue';

export default {
    name: 'Home',
    inject: ['isGoogleLoaded'],
    components: {
        NavPrimary,
        Map,
    },
}
</script>
