<template>
    <router-view
        class="router-view"
        @spotFocus="spotFocus"
        @fetchForecast="fetchForecast"
        v-slot="{ Component }"
    >
        <keep-alive include="Home">
            <component :is="Component" />
        </keep-alive>
    </router-view>
</template>

<script>
import {
    distance,
    requireForecast,
    requireSpotInfo,
    requireSpots,
    requireStationData,
    requireStationList
} from "./helpers";
import {provide, reactive, ref} from "vue";
import {MAPS_API_KEY} from "@/config";
export default {
    setup() {
        const swimSpots = reactive({
            spots: {},
        });
        const singleSpot = reactive({
            id: null,
            counter: 0,
        });
        const isGoogleLoaded = ref(false);
        provide('swimSpots', swimSpots);
        provide('isGoogleLoaded', isGoogleLoaded);
        return {
            swimSpots,
            isGoogleLoaded,
            singleSpot,
        };
    },
    mounted() {
        if( (window.google && window.google.maps) ) {
            this.initMap();
        } else{
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&callback=initMap`;
            script.async = true;
            window.initMap = this.initMap;
            document.body.appendChild(script);
        }
    },
    methods: {
        initMap() {
            this.isGoogleLoaded = true;
            requireSpots()
                .then((response) => {
                    const paginated = response.data;
                    const responseData = paginated.data;
                    if(! Array.isArray(responseData)) return;

                    responseData.forEach((receivedSwimSpot) => {
                        if(this.swimSpots.spots[receivedSwimSpot['id']]) return true;
                        this.swimSpots.spots[receivedSwimSpot['id']] = receivedSwimSpot;
                    });
                    this.swimSpots.spots = {...this.swimSpots.spots}
                })
        },
        spotFocus(spot) {
            this.$router.push({
                name: 'Live',
                params: {
                    id: spot.id
                },
            });
        },
        requireSpotInfo(id) {
            return new Promise((resolve) => {
                if(!this.swimSpots.spots[id]) {
                    requireSpotInfo(this.$route.params.id)
                        .then(response => this.swimSpots.spots[id] = response.data)
                        .then(resolve)
                } else resolve();
            });
        },
        fetchForecast(id) {
            this.requireSpotInfo(id)
                .then(() => this.requireForecast(id))
                .then(this.requireStations)
                .then(() => this.findClosestStation(id))
                .then(() => this.requireStationData(id))
                .then(stationData => this.parseStationData(id, stationData));
        },
        requireForecast(id) {
            if(! this.swimSpots.spots[id].weather_data) {

                return requireForecast(this.swimSpots.spots, id)
                    .then((response) => {
                        this.swimSpots.spots[id] = {
                            ...this.swimSpots.spots[id],
                            weather_data: response.weatherdata
                        }
                    }).catch(() => {
                        this.swimSpots.spots[id].weather_data = null;
                    });
            }
        },
        requireStations() {
            if(! this.swimSpots.stations) {
                return requireStationList().then((stations) => { return this.swimSpots.stations = stations; });
            }
        },
        findClosestStation(id) {
            const singleSpot = this.swimSpots.spots[id];
            if(! singleSpot.closestStation) {
                singleSpot.closestStation = Object.values(this.swimSpots.stations).reduce((a, b) => distance(singleSpot, a) < distance(singleSpot, b) ? a : b);
            }
        },
        requireStationData(id) {
            const singleSpot = this.swimSpots.spots[id];
            if(! singleSpot.closestStation.data) {
                const dateFrom = new Date(), dateTo = new Date();
                dateFrom.setMinutes(0,0,0);
                dateTo.setHours(dateFrom.getHours() + 24, 0, 0, 0);
                return requireStationData(dateFrom.toISOString(), dateTo.toISOString(), singleSpot.closestStation.stationID);
            }
        },
        parseStationData(id, data) {
            const singleSpot = this.swimSpots.spots[id];
            if(singleSpot.closestStation.data || !data) return;

            const columnNames = data.columnNames;
            const timeColumnIndex = columnNames.findIndex((column) => column === 'time');

            singleSpot.closestStation.data =
                data.rows.reduce((result, row) => {
                    const resultObject =
                        row.reduce((result, column, columnIndex)=>{
                            if(columnIndex === timeColumnIndex) return result;
                            const columnName = columnNames[columnIndex];

                            result[columnName] = column;
                            return result;
                        },{});
                    result.set(row[timeColumnIndex], resultObject);
                    return result;
                }, new Map())
        }
    }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}
html, body {
    height: 100%;
}
#app {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: 100%;
    height: 100%;
}
.router-view {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
.body-single__body {
    margin: 30px 0;
    padding: 10px 0;
    display: flex;
    justify-content: center;
}
</style>
