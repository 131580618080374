import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Primary/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/list',
        name: 'List',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "list" */ '../views/Primary/List.vue')
    },
    {
        path: '/favourites',
        name: 'Favourites',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "favourites" */ '../views/Primary/Favourites.vue')
    },
    {
        path: '/:id/info',
        name: 'Info',
        component: () => import(/* webpackChunkName: "info" */ '../views/Single/LocalInformation.vue')
    },
    {
        path: '/:id/live',
        name: 'Live',
        component: () => import(/* webpackChunkName: "live" */ '../views/Single/LiveInformation.vue')
    },
    {
        path: '/:id/forecast',
        name: 'Forecast',
        component: () => import(/* webpackChunkName: "forecast" */ '../views/Single/Forecast.vue')
    },
    {
        path: '/:id/connect',
        name: 'Connect',
        component: () => import(/* webpackChunkName: "connect" */ '../views/Single/Connect.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
