<template>
    <div class="overlay" :style="{
        height: height + 'px',
    }">
        <slot></slot>
    </div>
</template>
<script>
export default {
    data() {
        return {
            height: window.innerHeight,
        };
    },
    mounted() {
        window.addEventListener('resize', this.windowResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        windowResize() {
            this.height = window.innerHeight;
        }
    },
}
</script>
<style scoped>
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(42, 39, 51, 0.8);
        width: 100vw;
        height: 100vh;
    }
</style>
