<template>
    <div
        class="list-wrapper__row"
        v-for="spotId in spotsToShow"
        :key="spotId"
        @click="$emit('selectSpot', swimSpots.spots[spotId])"
    >
        <div class="entry__icon">
            <div class="entry__icon--wrapper">
                <img :src="icon">
            </div>
        </div>
        <div class="list-wrapper__columns-wrapper">
            <div class="list-wrapper__column-entry entry--name">{{ swimSpots.spots[spotId].name }}</div>
            <div class="list-wrapper__column-entry entry--town">{{ swimSpots.spots[spotId].city.name }}</div>
            <div class="list-wrapper__column-entry entry--county">{{ swimSpots.spots[spotId].city.county.name }}</div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['swimSpots'],
    props: ['spotsToShow', 'icon'],
    emits: ['selectSpot']
}
</script>
