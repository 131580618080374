<template>
    <div class="header">
        <div class="header__head" v-if="false">
            <div class="header__info">
                <div class="info__head">
                    Introduction to swim spots
                </div>
                <div class="info__body">
                    I.e Swim Ireland new interactive swim spot map provides all the latest weather information with ...
                </div>
            </div>
        </div>
        <div class="header__body" v-if="false">
            <nav class="navbar">
                <router-link class="nav__entry" to="/">
                    <span class="nav__link">
                        Map View
                    </span>
                </router-link>
                <router-link v-if="false" class="nav__entry" to="/list">
                    <span class="nav__link">
                        List View
                    </span>
                </router-link>
                <router-link v-if="false" class="nav__entry" to="/favourites">
                    <span class="nav__link">
                        Favourites
                    </span>
                </router-link>
            </nav>
        </div>
    </div>
</template>

<style scoped>
    .header__head {
        display: flex;
        justify-content: center;
        text-align: left;
        padding: 20px;
    }
    .header__info {
        padding: 10px;
        background-color: white;
        border: 1px solid black;
    }
    .header__info .info__head {
        margin-bottom: 10px;
    }
    .header__body {
        padding: 20px 0;
    }
    .navbar a.nav__entry {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    .navbar a.nav__entry.router-link-active {
        text-decoration: underline;
        text-decoration-color: white;
    }
    .navbar a.nav__entry .nav__link {
        padding: 10px 30px;
        background-color: rgb(80, 113, 190);
        color: white;
        border-radius: 10px;
        border: 2px solid rgb(55, 81, 139);
        font-weight: bold;
    }
    .navbar a.nav__entry.router-link-active .nav__link {
        background-color: rgb(103, 201, 203);
    }
    .navbar .nav__entry + .nav__entry {
        margin-left: 30px;
    }
</style>
