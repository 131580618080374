<template>
    <div ref="mapWrapper" class="mapWrapper"
         :style="{
                height: mapHeight + 'px',
            }"
    >
        <div ref="mapElem" id="mapElem"></div>
        <div class="map__header">
            <div class="logo__shell">
                <div class="logo" ref="logo">
                    <img src="@/assets/img/logo.svg" class="logo__image">
                </div>
                <div ref="search" class="search">
                    <div class="search__wrapper">
                        <div class="search__entry input__wrapper">
                            <input
                                @input="debounceSearch"
                                v-model="filter.searchText"
                                type="text"
                            >
                            <div
                                class="clear"
                                v-if="filter.searchText && !searchLoading"
                                @click="clearSearch"
                            >
                                <img src="@/assets/img/clear.svg" alt="">
                            </div>
                            <div class="spinner" v-if="searchLoading">
                                <img src="@/assets/img/spinner.svg">
                            </div>
                        </div>
                        <div class="search__entry search__entry--nowrap-group">
                            <div class="search__entry">
                                <select @input="onRadiusChange" v-model="filter.radius">
                                    <option value="5">5km</option>
                                    <option value="10">10km</option>
                                    <option value="20">20km</option>
                                    <option value="50">50km</option>
                                    <option value="100">100km</option>
                                    <option value="200">200km</option>
                                    <option value="500">500km</option>
                                </select>
                            </div>

                            <div @keydown.esc="closeList" tabindex="2" @click="openList" class="search__entry list-view-button">
                                <img src="@/assets/img/list-ul.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <list-view
            v-if="showList"
            :spotsToShow="filter.spots"
            @close-list="closeList"
            @select-spot="selectSpot($event)"></list-view>
    </div>
</template>

<script>
import ListView from "@/components/ListView";

import debounce from "debounce";
import {getDistanceFromLatLonInKm} from "@/helpers";
import {MAP_STYLE} from "@/config";

export default {
    inject: ['swimSpots'],
    components: {
        ListView
    },
    data() {
        return {
            mapHeight: document.body.clientHeight,
            showList: false,
            searchLoading: false,
            bounds: null,
            userAllowedGeo: false,
            filter: {
                searchText: null,
                radius: "20",
                spots: [],
            },
            highlightedMarkers: [],
            geocoder: null,
        }
    },
    mounted() {
        this.markers = {};
        this.handleResize();
        if(window.google) {
            this.onMapsSdkLoaded();
            this.handleMarkerInput();
            this.moveToFitAllMarkers();
            this.geocoder = new window.google.maps.Geocoder();
            if(!this.filter.searchText && this.swimSpots?.spots) this.filter.spots = {
                close: Object.keys(this.swimSpots.spots),
                exact: [],
            };
        }
    },
    methods: {
        resize() {
            this.mapHeight = window.innerHeight;
        },
        handleResize() {
            window.addEventListener('resize', this.resize);
        },
        unmountResize() {
            window.removeEventListener('resize', this.resize);
        },
        openList() {
            this.showList = true;
        },
        closeList() {
            this.showList = false;
        },
        clearSearch() {
            this.filter.searchText = '';
            this.filter.spots = {
                close: Object.keys(this.swimSpots.spots),
                exact: [],
            };
            for(let marker of Object.values(this.markers)) {
                marker.setMap(this.map)
            }
            this.resetHightLight();
        },
        highlightMarkers(pinsIds) {
            this.highlightedMarkers = [...this.highlightedMarkers, ...pinsIds];
            for(let pinId of pinsIds) {
                const marker = this.markers[pinId]
                marker.zIndex = 1000;
                marker.setIcon({
                    url: require('@/assets/img/swim-map-pin-black.svg'),
                    scaledSize: new window.google.maps.Size(51.71*3/4, 64.11*3/4),
                    anchor: new window.google.maps.Point(51.71*3/8, 64.11*3/4),
                });
            }
        },
        resetHightLight() {
            for(let spotId of this.highlightedMarkers) {
                this.markers[spotId].setIcon({
                    url: require('@/assets/img/swim-map-pin.svg'),
                    scaledSize: new window.google.maps.Size(51.71*3/4, 64.11*3/4),
                    anchor: new window.google.maps.Point(51.71*3/8, 64.11*3/4),
                });
                this.markers[spotId].zIndex = 0;
            }
        },
        selectSpot(swimSpot) {
            this.showList = false;
            const marker = this.markers[swimSpot.id];
            const spotPosition = marker.getPosition();
            this.resetHightLight()
            this.highlightMarkers([ swimSpot.id ])

            this.map.setCenter(spotPosition);
            this.map.setZoom(13);
        },
        onRadiusChange($event) {
            this.filter.radius = $event.target.value;
            this.debounceSearch();
        },
        centerOnCurrentLocation() {
            if (this.map && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const initialLocation = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    this.map.setCenter(initialLocation);
                    this.map.setZoom(14);
                    this.userAllowedGeo = true;
                });
            }
        },
        onMapsSdkLoaded() {
            this.map = new window.google.maps.Map(this.$refs.mapElem, {
                ...window.google.lastSettings,
                mapTypeControl: false,
                styles: MAP_STYLE,
            });
            if(! window.google.lastSettings) this.centerOnCurrentLocation();
        },
        handleMarkerInput() {
            const newEntries = Object.entries(this.swimSpots.spots);
            newEntries.forEach((entry) => {
                const [id, receivedSwimSpot] = entry;
                if(this.markers[id]) return true;

                this.markers[id] =
                    new window.google.maps.Marker({
                        position: {
                            lat: receivedSwimSpot.latitude * 1.0,
                            lng: receivedSwimSpot.longitude * 1.0
                        },
                        map: this.map,
                        title: receivedSwimSpot.name,
                        icon: {
                            url: require('@/assets/img/swim-map-pin.svg'),
                            scaledSize: new window.google.maps.Size(51.71*3/4, 64.11*3/4),
                            anchor: new window.google.maps.Point(51.71*3/8, 64.11*3/4),
                        },
                        zIndex: 0,
                        optimized: true,
                    });
                this.markers[id].addListener('click', () => {
                    this.$emit('spotFocus', receivedSwimSpot);
                })
            });
        },
        moveToFitAllMarkers() {
            this.bounds = new window.google.maps.LatLngBounds();
            for(let id in this.swimSpots.spots) {
                this.bounds.extend({
                    lat: this.swimSpots.spots[id].latitude * 1.0,
                    lng: this.swimSpots.spots[id].longitude * 1.0
                });
            }
            this.map.fitBounds(this.bounds);
            const center = this.map.getCenter();
            if(center) {
                window.google.lastSettings = {
                    center: {
                        lat: center.lat(),
                        lng: center.lng(),
                    },
                    zoom: this.map.zoom
                }
            }
        },
        setLoading(value) {
            this.searchLoading = value;
        },
        debounceSearch: debounce(function() {
            this.setLoading(true);
            if(!this.filter.searchText) {
                this.clearSearch();
                this.setLoading(false);
                return;
            }
            this.filter.spots = {
                close: [],
                exact: [],
            };
            const request = {
                address: this.filter.searchText,
                bounds: this.bounds,
            };

            this.geocoder.geocode(request)
                .then(({results}) => {
                    if(!results || !results.length) return;
                    const filteredSpots = {};
                    const cornerPoint = results[0].geometry.viewport.getNorthEast();
                    const centerPoint = results[0].geometry.viewport.getCenter();

                    const viewportRadius = getDistanceFromLatLonInKm(cornerPoint, centerPoint);
                    const maxDistanceToCenter = viewportRadius + this.filter.radius * 1.0;

                    for(let [id, marker] of Object.entries(this.markers)) {
                        const distanceToCenter = getDistanceFromLatLonInKm(centerPoint, marker.position);
                        if(distanceToCenter <= maxDistanceToCenter) {
                            filteredSpots[id] = true;
                        }
                    }
                    return filteredSpots;
                })
                .catch(() => {})
                .then((filteredSpots = {}) => {
                    let spotPropSearchResult = Object.entries(this.swimSpots.spots)
                        .filter(([, spot]) => {
                            const spotSearchText = `${spot.name}`;
                            return spotSearchText.toLowerCase().trim().indexOf(this.filter.searchText.toLowerCase().trim()) !== -1;
                        }).map(([id, ]) => id);
                    this.resetHightLight();
                    this.highlightMarkers(spotPropSearchResult)

                    spotPropSearchResult
                        .filter((id) => {
                            return Object.prototype.hasOwnProperty.call(filteredSpots, id);
                        });
                    return this.filter.spots = {
                        close: [
                            ...Object.keys(filteredSpots).filter((id) => {
                                return spotPropSearchResult.findIndex((exactId) => exactId !== id) === -1;
                            })
                        ],
                        exact: [
                            ...spotPropSearchResult
                        ]
                    };
                })
                .then((filteredSpotsArray) => {
                    const allMatchedSpots = [
                        ...filteredSpotsArray.close,
                        ...filteredSpotsArray.exact,
                    ];
                    const newBounds = allMatchedSpots.length? new window.google.maps.LatLngBounds() : this.bounds;
                    Object.entries(this.markers)
                        .forEach(([,marker]) => {
                            marker.setMap(null);
                        });
                    allMatchedSpots.forEach((spotId) => {
                        const marker = this.markers[spotId];
                        marker.setMap(this.map);
                        newBounds.extend(marker.position);
                    })
                    return newBounds;
                })
                .then((newBounds) => {
                    this.map.fitBounds(newBounds);
                    window.google.maps.event.addListenerOnce(this.map, 'bounds_changed', function() {
                        this.setZoom(Math.min(12, this.getZoom()));
                    });
                    this.setLoading(false)
                });
        }, 500),
    },
    unmounted() {
        this.unmountResize()
    },
    watch: {
        swimSpots: {
            handler: function() {
                this.handleMarkerInput();
                if(!this.userAllowedGeo && !window.google.lastSettings) this.moveToFitAllMarkers();
                if(!this.filter.searchText) this.filter.spots = {
                    close: Object.keys(this.swimSpots.spots),
                    exact: [],
                };
            },
            deep: true,
        }
    }
}
</script>

<style lang="scss" scoped>
.mapWrapper {
    display: flex;
    justify-content: center;

    #mapElem {
        width: 100%;
        height: 100%;
    }

    .map__header {
        position: absolute;
        display: flex;
        left: 0;

        .logo__shell {
            padding: 10px 0 10px 10px;
            display: flex;

            .logo {
                display: none;
                padding-right: 45px;

                img {
                    user-select: none;
                    width: auto;
                    height: auto;
                    max-width: 130px;
                    max-height: 130px;
                }
            }
        }

        .search {
            display: inline;

            .search__wrapper {
                display: flex;
                flex-wrap: wrap;
                width: 80vw;

                .search__entry {
                    display: flex;
                    &.search__entry--nowrap-group {
                        margin: 10px 0 0 0;
                        user-select: none;
                    }
                    & + .search__entry:not(.search__entry--nowrap-group) {
                        margin-left: 10px;
                    }
                    &.input__wrapper {
                        display: flex;
                        flex-basis: 100%;
                        position: relative;
                    }
                }
            }

            input, select {
                font-size: 100%;
                display: block;
                padding: 21px 0 21px 25px;
                border-radius: 10px;
                border: 0;
                filter: drop-shadow(0 30px 50px #2A273329);
                outline: none;
                flex: 0 0 100%;
            }

            input {
                padding-right: 55px;
                max-width: calc(100% - 80px - 40px);
                width: 100%;
            }

            select {
                appearance: none;
                padding: 19px 60px 19px 26px;

                background: white url("~@/assets/img/caret-down-fill.svg") no-repeat calc(100% - 25px) 50%;
            }

        .list-view-button {
            outline: none;
            flex: 0 0 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(255,255,255);
            border-radius: 10px;
        }
    }
    }
}
.spinner, .clear {
    position: absolute;
    display: flex;
    height: 100%;
    width: 55px;
    right: 0;
    justify-content: center;
    align-items: center;
    &.spinner img {
        height: 80%;
    }
    &.clear img {
        height: 1.5em;
    }
}
@media (min-width:320px) {
    .mapWrapper .map__header .search input {
        max-width: calc(100% - 80px);
    }
}
@media (min-width:681px)  {
    .mapWrapper .map__header {
        .logo__shell {
            padding: 40px 60px 40px 70px;
        }

        .search {
            input {
                flex-basis: auto;
            }

            select {
                margin-top: 0;
            }
        }
    }
}
@media (min-width:844px)  {
    .mapWrapper .map__header .search {
        .search__wrapper {
            width: auto;
            flex-wrap: nowrap;

            .search__entry.search__entry--nowrap-group {
                margin: 0 0 0 10px;
            }
        }

        input {
            width: 435px;
        }
    }
}
@media (min-width:1025px) {
    .mapWrapper .map__header .logo__shell .logo {
        display: block;
    }
}
</style>
